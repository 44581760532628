import { useState, useEffect } from 'react';
import { Breadcrumb, Layout, Tabs, List, Row, Col, Image, Typography, Tag, message, theme } from 'antd';
import { Link } from 'react-router-dom';

const { Content } = Layout;
const { Text } = Typography;

const BangumiItem = ({ item }) => {
  return (
    <List.Item key={item.title.jp}>
      <Row gutter={8} className="bangumiRow">
        <Col sm={{ flex: "85px" }} xs={{ flex: "100%" }} className="bangumiColImage" style={{ "--bangumionair-display": item.thumbnail ? false : "none" }}>
          <Image alt={item.title.cn ? item.title.cn : item.title.jp} placeholder={true} src={item.thumbnail} fallback="https://lain.bgm.tv/img/no_icon_subject.png"
            preview={
              item.preview ? {
                src: item.preview
              } : false
            }
            style={{
              maxWidth: 150,
              textAlign: "center"
            }} />
        </Col>
        <Col sm={{ flex: "auto" }} xs={{ flex: "100%" }}>
          <List.Item.Meta
            title={
              <>
                <span style={{ fontWeight: 600, fontSize: 20 }}>{item.title.cn ? item.title.cn : item.title.jp}</span>
                {item.new && <Tag bordered={false} color="processing" style={{ marginLeft: 5 }}>New</Tag>}
              </>
            }
            description={item.title.cn && <Text type="secondary" style={{ fontWeight: 500 }}>{item.title.jp}</Text>}
          />
          <Row gutter={16}>
            <Col span={24} sm={11}>
              <div>开播日期：{item.begin}</div>
              <div>放送时间：{item.broadcast.week} {item.broadcast.time}</div>
            </Col>
            <Col span={24} sm={13}>
              <div className="bangumiSpan">
                相关信息：
                {
                  item.info[0] ? item.info.map((item) => {
                    return (
                      <span><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></span>
                    )
                  }) : "暂无"
                }
              </div>
            </Col>
          </Row>
          <Row gutter={0}>
            <Col>
              <div className="bangumiSpan">
                配信：
                {
                  item.onair[0] ? item.onair.map((item) => {
                    return (
                      <span><a href={item.url} target="_blank" rel="noopener noreferrer">{item.site}</a></span>
                    )
                  }) : "暂无"
                }
              </div>
            </Col>
          </Row>

        </Col>
      </Row>
    </List.Item>
  );
}

const BangumiOnAir = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [listLoading, setListLoading] = useState(true);
  const [resultList, setResultList] = useState([]);

  const tabItem = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"].map((week, index) => ({
    key: index,
    label: week,
    children: <List
      itemLayout="horizontal"
      loading={listLoading}
      dataSource={resultList[index]}
      renderItem={(item) => (
        <BangumiItem item={item} />
      )}
    />,
  }));

  useEffect(() => {
    fetch('https://tools.b9.ink/api/bangumiOnAir.php', {
      contentType: false,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === 0) {
          setResultList(data.data);
          setListLoading(false);
        } else {
          message.error(data.message);
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  }, []);

  return (
    <>
      <Breadcrumb
        style={{ margin: '16px 4px' }}
        items={[
          {
            title: <Link to='/'>首页</Link>,
          },
          {
            title: '番剧放送时间表',
          }
        ]}
      />
      <Layout
        style={{
          padding: '24px 0',
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
        <Content
          style={{
            padding: '0 24px',
          }}
        >
          <Tabs defaultActiveKey={new Date().getDay()} items={tabItem} animated={{ inkBar: true, tabPane: true }} className="bangumiOnAir" />
        </Content>
      </Layout>
    </>
  );
};

export default BangumiOnAir;
