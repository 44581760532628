import { Breadcrumb, Layout, theme } from 'antd';
import { Link } from 'react-router-dom';

const { Content } = Layout;

const About = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <Content
      style={{
        padding: '0 48px',
        width: '100%',
        maxWidth: '1208px',
        margin: 'auto',
      }}
    >
      <Breadcrumb
        style={{ margin: '16px 4px' }}
        items={[
          {
            title: <Link to='/'>首页</Link>,
          },
          {
            title: 'About',
          }
        ]}
      />      <Layout
        style={{
          padding: '24px 0',
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
        <Content
          style={{
            padding: '0 24px',
            minHeight: 280,
          }}
        >
          about
        </Content>
      </Layout>
    </Content>
  );
};
export default About;
