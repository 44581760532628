import { useState, useRef } from 'react';
import { Breadcrumb, Layout, Row, Col, Form, Input, Card, Space, Button, message, theme } from 'antd';
import { Link } from 'react-router-dom';

const { Content } = Layout;
const { Meta } = Card;

const BiliCover = () => {
  const {
    token: { colorBgContainer, borderRadiusLG, colorTextTertiary },
  } = theme.useToken();

  const [showResult, setShowResult] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [resultTitle, setResultTitle] = useState('');
  const [resultImageSrc, setResultImageSrc] = useState('');

  const divRef = useRef();

  const onClickSelect = () => {
    divRef.current.select();
  }

  const handleSubmit = (value) => {
    setButtonLoading(true);

    if (value.vid.length === 0) {
      message.error("参数不能为空");
      setButtonLoading(false);
      return false;
    }

    let formData = new FormData();
    formData.append("id", value.vid);

    fetch('https://api.yuncaioo.com/bilicover', {
      method: 'POST',
      contentType: false,
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        if (data.code === 200) {
          setResultTitle(data.title);
          setResultImageSrc(data.url);
          setShowResult(true);
        } else {
          message.error(data.msg);
        }
        setButtonLoading(false);

      })
      .catch((err) => {
        message.error(err.message);
        setButtonLoading(false);
      });
  };

  return (
    <>
      <Breadcrumb
        style={{ margin: '16px 4px' }}
        items={[
          {
            title: <Link to='/'>首页</Link>,
          },
          {
            title: '哔哩哔哩视频封面获取',
          }
        ]}
      />
      <Layout
        style={{
          padding: '24px 0',
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
        <Content
          style={{
            padding: '0 24px',
          }}
        >
          <Row gutter={[16, 8]}>
            <Col span={24} lg={12}>
              <Space direction="vertical" style={{ width: '100%' }} >
                <Form layout="vertical" onFinish={handleSubmit}>
                  <Form.Item label="AV / BV 号" name="vid" rules={[{ required: true, message: 'AV / BV 号不能为空' }]}>
                    <Input placeholder="av810872" />
                  </Form.Item>
                  <Button type="primary" htmlType="submit" loading={buttonLoading} style={{ float: "right" }}>提交</Button>
                </Form>
                {showResult && (
                  <Card>
                    <Space direction="vertical" style={{ width: '100%' }} >
                      图片下载地址
                      <Input ref={divRef} placeholder="下载地址" onClick={onClickSelect} value={resultImageSrc} />
                    </Space>
                  </Card>
                )}
              </Space>

            </Col>
            <Col span={24} lg={12}>
              {showResult ? (
                <Card
                  cover={<img alt={resultTitle} src={resultImageSrc} referrerpolicy="no-referrer" />}
                >
                  <Space direction="vertical" style={{ width: '100%' }} >
                    <Meta title={resultTitle}></Meta>
                  </Space>
                </Card>
              ) : (
                <Card>
                  <h1 style={{ textAlign: 'center', color: colorTextTertiary }}>No Image</h1>
                </Card>
              )}

            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export default BiliCover;
