import { useState } from 'react';
import { Breadcrumb, Layout, Row, Col, Form, InputNumber, Card, Button, theme } from 'antd';
import { Link } from 'react-router-dom';

const { Content } = Layout;

const Random = () => {
  const {
    token: { colorBgContainer, borderRadiusLG, colorTextTertiary },
  } = theme.useToken();

  const [showResult, setShowResult] = useState(false);
  const [resultNumber, setResultNumber] = useState('');

  const handleSubmit = (values) => {
    let result = '';
    for (let i = 0; i < values.count; i++) {
      result += (Math.floor(Math.random() * (values.max - values.min + 1)) + values.min) + ' ';
    }

    if (result.length !== 0) {
      setResultNumber(result);
      setShowResult(true);
    } else {
      setShowResult(false);
    }
  };

  return (
    <>
      <Breadcrumb
        style={{ margin: '16px 4px' }}
        items={[
          {
            title: <Link to='/'>首页</Link>,
          },
          {
            title: '随机数生成',
          }
        ]}
      />
      <Layout
        style={{
          padding: '24px 0',
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
        <Content
          style={{
            padding: '0 24px',
          }}
        >
          <Row gutter={[16, 8]}>
            <Col span={24} lg={12}>
              <Form layout="vertical" initialValues={{ min: 0, max: 100, count: 10 }} onFinish={handleSubmit}>
                <Form.Item label="最小值" name="min">
                  <InputNumber placeholder="0" style={{ width: '100%' }} required />
                </Form.Item>
                <Form.Item label="最大值" name="max">
                  <InputNumber placeholder="100" style={{ width: '100%' }} required />
                </Form.Item>
                <Form.Item label="生成数量" name="count">
                  <InputNumber placeholder="10" style={{ width: '100%' }} required />
                </Form.Item>
                <Button type="primary" htmlType="submit" style={{ float: "right" }}>生成</Button>
              </Form>
            </Col>
            <Col span={24} lg={12}>
              <div style={{ paddingBottom: 8 }}>
                结果
              </div>
              {showResult ? (
                <Card>
                  {resultNumber}
                </Card>
              ) : (
                <Card>
                  <h1 style={{ textAlign: 'center', color: colorTextTertiary }}>No Result</h1>
                </Card>
              )}
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export default Random;
