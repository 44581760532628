import { useState } from 'react';
import { Breadcrumb, Layout, Row, Col, Form, Input, InputNumber, Cascader, Card, Button, message, theme, Space } from 'antd';
import { Link } from 'react-router-dom';
import distCode from '../Data/distCode.json';

const { Content } = Layout;

const ZipCode = () => {
  const {
    token: { colorBgContainer, borderRadiusLG, colorTextTertiary },
  } = theme.useToken();

  const [showResult, setShowResult] = useState(false);
  const [buttonAddrLoading, setButtonAddrLoading] = useState(false);
  const [buttonCodeLoading, setButtonCodeLoading] = useState(false);

  const [resultNumber, ] = useState(''); //setResultNumber

  const [selectPlace, setSelectPlace] = useState('上海市|上海市|黄浦区');

  const handleSubmitAddr = (values) => {
    setButtonAddrLoading(true);

    let formData = new FormData();
    formData.append("type", "addr");
    formData.append("distCode", values.distCode[2]);
    formData.append("transPlace", selectPlace);
    formData.append("street", values.street);

    fetch('https://tools.b9.ink/api/zipCode.php', {
      method: 'POST',
      contentType: false,
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        if (data.code === 0) {
          setShowResult(true);
        } else {
          message.error(data.message);
        }
        setButtonAddrLoading(false);

      })
      .catch((err) => {
        message.error(err.message);
        setButtonAddrLoading(false);
      });
  };

  const handleSubmitCode = (values) => {
    setButtonCodeLoading(true);

    let formData = new FormData();
    formData.append("type", "code");
    formData.append("zipCode", values.code);

    fetch('https://tools.b9.ink/api/zipCode.php', {
      method: 'POST',
      contentType: false,
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === 0) {
          setShowResult(true);
        } else {
          message.error(data.message);
        }
        setButtonCodeLoading(false);

      })
      .catch((err) => {
        message.error(err.message);
        setButtonCodeLoading(false);
      });
  };

  const onChange = (value, selectedOptions) => {
    setSelectPlace(selectedOptions[0].label + '|' + selectedOptions[1].label + '|' + selectedOptions[2].label);
  };

  return (
    <>
      <Breadcrumb
        style={{ margin: '16px 4px' }}
        items={[
          {
            title: <Link to='/'>首页</Link>,
          },
          {
            title: '邮政编码',
          }
        ]}
      />      <Layout
        style={{
          padding: '24px 0',
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
        <Content
          style={{
            padding: '0 24px',
          }}
        >
          <Row gutter={[16, 8]}>
            <Col span={24} lg={12}>
              <Space direction="vertical" style={{ width: '100%' }} >
                <Form initialValues={{
                }} onFinish={handleSubmitAddr}>
                  <Form.Item label="行政区划" name="distCode" rules={[{ required: true, message: "必选" }]}>
                    <Cascader options={distCode} onChange={onChange} placeholder="选择地区" />
                  </Form.Item>
                  <Form.Item label="详细地址" name="street" rules={[{ required: true, message: "必填" }]}>
                    <Input placeholder="请输入详细地址" />
                  </Form.Item>
                  <Button type="primary" htmlType="submit" loading={buttonAddrLoading} style={{ float: "right" }}>查询</Button>
                </Form>
                <Form
                  layout="inline"
                  initialValues={{
                  }} onFinish={handleSubmitCode}>
                  <Form.Item label="邮编查询" name="code" rules={[{ required: true, message: "必填，格式为 6 位数字", type: "number", min: 100000, max: 999999 }]}>
                    <InputNumber placeholder="100001" style={{ width: "100%" }} required />
                  </Form.Item>
                  <Button type="primary" htmlType="submit" loading={buttonCodeLoading} style={{ float: "right" }}>查询</Button>
                </Form>
              </Space>
            </Col>
            <Col span={24} lg={12}>
              <div style={{ paddingBottom: 8 }}>
                结果
              </div>
              {showResult ? (
                <Card>
                  <h1 style={{ textAlign: 'center' }}>{resultNumber}</h1>
                </Card>
              ) : (
                <Card>
                  <h1 style={{ textAlign: 'center', color: colorTextTertiary }}>No Result</h1>
                </Card>
              )}
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export default ZipCode;
