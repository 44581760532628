import { Col, Row, Button, Input, Space, Layout } from 'antd';
import { Link } from 'react-router-dom';
import Router from './Router';

const Home = () => {
  const onSearch = (value, _e, info) => {
    console.log(info?.source, value);
  }

  return (
    <Layout
      key="home"
      style={{
        padding: '24px 0',
      }}
    >
      <Space direction="vertical">
        <Input
          placeholder="搜索工具 (暂不可用)"
          allowClear
          size="large"
          onChange={onSearch}
          style={{
            marginBottom: '24px'
          }}
          disabled
        />
        <Row gutter={[16, 20]}>
          {
            Router.filter((item) => item.menu)
              .map((item) => {
                if (item.blank)
                  return (
                    <Col lg={6} sm={12} xs={24}><Link to={item.path} target='_blank'><Button type="primary" block>{item.title}</Button></Link></Col>
                  )
                else
                  return (
                    <Col lg={6} sm={12} xs={24}><Link to={item.path}><Button type="primary" block>{item.title}</Button></Link></Col>
                  )
              })
          }
        </Row>
      </Space>
    </Layout>
  );
};
export default Home;
