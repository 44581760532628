import './App.css'
import { React, useState, useEffect } from 'react';
import { Routes, Route, BrowserRouter, Link } from 'react-router-dom'
import { ConfigProvider, Layout, Typography, theme } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import QueueAnim from 'rc-queue-anim';
//import Navbar from './Navbar';
import Router from './Router';

const { getDesignToken } = theme;
const { Content, Footer } = Layout;
const { Title } = Typography;

const AppVersion = '0.1.30a';

/* Title */
const DomTitle = ({ item }) => {
  if ('title' in item) {
    document.title = item.title + " - FHYunCai's ToolBox";
  } else {
    document.title = "FHYunCai's ToolBox";
  }
  return <QueueAnim duration={[450, 0]} type="bottom"><div key={item.path}><item.component /></div></QueueAnim>
}

const App = () => {

  const [themeDark, setThemeDark] = useState(false);

  const themeConfig = {
    algorithm: themeDark ? theme.darkAlgorithm : undefined,
    token: {
      colorBgBase: themeDark ? "#0E0E0E" : "#FFF",
    },
    components: {
      Form: {
        itemMarginBottom: 10,
      },
    },
  }

  useEffect(() => {
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      setThemeDark(true);
    } else {
      setThemeDark(false);
    }
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (event) => {
        if (event.matches) {
          setThemeDark(true);
        } else {
          setThemeDark(false);
        }
      })
  }, []);

  const globalToken = getDesignToken(themeConfig);

  return (
    <BrowserRouter>
      <ConfigProvider locale={zhCN} theme={themeConfig}>
        <Layout>
          <Title style={{
            textAlign: 'center',
          }}>
            <Link to="/" className='logo' style={{
              color: globalToken.colorText,
            }}>
              FHYunCai's ToolBox
            </Link>
          </Title>

          <Content className="content">
            <Routes>
              {
                Router.filter((item) => item.component)
                  .map((item) => {
                    return (
                      <Route
                        exact
                        key={item.path}
                        path={item.path}
                        element={<DomTitle item={item} />}
                      />
                    );
                  })
              }
            </Routes>
          </Content>

          <Footer
            style={{
              textAlign: 'center',
            }}
          >
            <div>ver.{AppVersion}早期测试版</div>
            <div>© {new Date().getFullYear()} FHYunCai</div>
          </Footer>
        </Layout>
      </ConfigProvider>
    </BrowserRouter >
  );
};
export default App;
