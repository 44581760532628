import { useState } from 'react';
import { Breadcrumb, Layout, Row, Col, Form, Input, Card, Button, theme } from 'antd';
import { Link } from 'react-router-dom';

const { Content } = Layout;

const Eat = () => {
  const {
    token: { colorBgContainer, borderRadiusLG, colorTextTertiary },
  } = theme.useToken();

  const [timer, setTimer] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [buttonValue, setbuttonValue] = useState('开始');
  const [resultNumber, setResultNumber] = useState('');

  const handleSubmit = (values) => {
    if (scroll) {
      stopScroll();
    } else {
      let splitText = values.list.split(' ');
      let filteredSplitText = splitText.reduce((acc, i) => i ? [...acc, i] : acc, []);

      startScroll(filteredSplitText);
      setShowResult(true);
    }
  };

  const showDish = (list) => {
    let index = Math.floor(Math.random() * (list.length));
    setResultNumber(list[index]);
  }

  const startScroll = (list) => {
    showDish(list);
    const timer = setInterval(showDish, 50, list);
    setTimer(timer);
    setbuttonValue("停止");
    setScroll(true);
  }

  const stopScroll = () => {
    clearInterval(timer);
    setbuttonValue("开始");
    setScroll(false);
  }

  return (
    <>
      <Breadcrumb
        style={{ margin: '16px 4px' }}
        items={[
          {
            title: <Link to='/'>首页</Link>,
          },
          {
            title: '今天吃什么',
          }
        ]}
      />
      <Layout
        style={{
          padding: '24px 0',
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
        <Content
          style={{
            padding: '0 24px',
          }}
        >
          <Row gutter={[16, 8]}>
            <Col span={24} lg={12}>
              <Form layout="vertical" initialValues={{
                list: "馄饨 烩面 热干面 刀削面 油泼面 炸酱面 炒面 重庆小面 米线 酸辣粉 土豆粉 螺狮粉 凉皮儿 麻辣烫 肉夹馍 羊肉泡馍 炒饭 盖浇饭 烤肉饭 黄焖鸡米饭 麻辣香锅 火锅 酸菜鱼 烤串 披萨 烤鸭 汉堡 炸鸡 寿司 煎饼果子 南瓜粥 小龙虾 牛排 砂锅 大排档 馒头 西餐 自助餐 小笼包 水果 西北风 烧烤 泡面 水饺 日本料理 涮羊肉 兰州拉面 肯德基 面包 臊子面 小笼包 麦当劳 沙县小吃 烤鱼 海鲜 铁板烧 萨莉亚 韩国料理 鸭血粉丝汤",
              }} onFinish={handleSubmit}>
                <Form.Item label="菜单列表" name="list">
                  <Input.TextArea autoSize={{ minRows: 2, maxRows: 4 }} placeholder="馄饨 炸酱面 炒饭..." required />
                </Form.Item>
                <Button type="primary" htmlType="submit" style={{ float: "right" }}>{buttonValue}</Button>
              </Form>
            </Col>
            <Col span={24} lg={12}>
              <div style={{ paddingBottom: 8 }}>
                结果
              </div>
              {showResult ? (
                <Card>
                  <h1 style={{ textAlign: 'center' }}>{resultNumber}</h1>
                </Card>
              ) : (
                <Card>
                  <h1 style={{ textAlign: 'center', color: colorTextTertiary }}>No Result</h1>
                </Card>
              )}
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export default Eat;
