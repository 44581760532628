import { useState } from 'react';
import { Breadcrumb, Layout, Row, Col, Form, Input, Card, Typography, List, Space, Checkbox, Button, theme } from 'antd';
import { Link } from 'react-router-dom';
import { enc, MD5, SHA1, SHA3, SHA224, SHA256, SHA384, SHA512 } from 'crypto-js';

const { Content } = Layout;

const Random = () => {
  const {
    token: { colorBgContainer, borderRadiusLG, colorTextTertiary },
  } = theme.useToken();

  const [hashUpper, setHashUpper] = useState(false);

  const [showResult, setShowResult] = useState(false);
  const [resultData, setResultData] = useState([]);

  const handleSubmit = (values) => {
    let resultMD5 = MD5(values.text).toString(enc.Hex);
    let resultSHA1 = SHA1(values.text).toString(enc.Hex);
    let resultSHA3 = SHA3(values.text).toString(enc.Hex);
    let resultSHA224 = SHA224(values.text).toString(enc.Hex);
    let resultSHA256 = SHA256(values.text).toString(enc.Hex);
    let resultSHA384 = SHA384(values.text).toString(enc.Hex);
    let resultSHA512 = SHA512(values.text).toString(enc.Hex);

    if (hashUpper) {
      resultMD5 = resultMD5.toUpperCase();
      resultSHA1 = resultSHA1.toUpperCase();
      resultSHA3 = resultSHA3.toUpperCase();
      resultSHA224 = resultSHA224.toUpperCase();
      resultSHA256 = resultSHA256.toUpperCase();
      resultSHA384 = resultSHA384.toUpperCase();
      resultSHA512 = resultSHA512.toUpperCase();
    }

    setResultData([
      ["MD5", resultMD5],
      ["SHA1", resultSHA1],
      ["SHA3", resultSHA3],
      ["SHA224", resultSHA224],
      ["SHA256", resultSHA256],
      ["SHA384", resultSHA384],
      ["SHA512", resultSHA512],
    ]);
    setShowResult(true);
  };

  const onChange = (e) => setHashUpper(e.target.checked);

  return (
    <>
      <Breadcrumb
        style={{ margin: '16px 4px' }}
        items={[
          {
            title: <Link to='/'>首页</Link>,
          },
          {
            title: 'Hash计算',
          }
        ]}
      />
      <Layout
        style={{
          padding: '24px 0',
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
        <Content
          style={{
            padding: '0 24px',
          }}
        >
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <Form layout="vertical" initialValues={{ min: 0, max: 100, count: 10 }} onFinish={handleSubmit}>
                <Form.Item label="文本内容" name="text">
                  <Input.TextArea autoSize={{ minRows: 2, maxRows: 4 }} rules={[{ required: true, message: '文本内容不能为空' }]} required />
                </Form.Item>
                <Space align="center" direction="inline" style={{ marginTop: 10, float: "right" }} >
                  <Checkbox onChange={onChange} checked={hashUpper}>大写</Checkbox>
                  <Button type="primary" htmlType="submit">生成</Button>
                </Space>
              </Form>
            </Col>
            <Col span={24}>
              <div style={{ paddingBottom: 8 }}>
                结果
              </div>
              {showResult ? (
                <Card>
                  <List
                    dataSource={resultData}
                    renderItem={(item) => (
                      <List.Item>
                        {item[0]}：<Typography.Text code>{item[1]}</Typography.Text>
                      </List.Item>
                    )}
                  >
                  </List>
                </Card>
              ) : (
                <Card>
                  <h1 style={{ textAlign: 'center', color: colorTextTertiary }}>No Result</h1>
                </Card>
              )}
            </Col>
            <Col span={24}>
              <Card>
                <Typography>
                  <ul style={{ marginBlock: 0 }}>
                    <li>
                      计算输入内容的 HASH 值
                    </li>
                    <li>
                      支持 MD5，SHA1，SHA3，SHA224，SHA256，SHA384，SHA512
                    </li>
                  </ul>
                </Typography>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export default Random;
