import { useState } from 'react';
import { Breadcrumb, Layout, Typography, Row, Col, Form, Radio, Upload, Card, List, Space, Button, message, theme } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { Content } = Layout;

const SearchPic = () => {
  const {
    token: { colorBgContainer, borderRadiusLG, colorTextTertiary },
  } = theme.useToken();

  const [showResult, setShowResult] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [disabledRadio, setDisabledRadio] = useState(false);

  const [fileList, setFileList] = useState([]);

  const [resultData, setResultData] = useState([]);
  const [current, setCurrent] = useState(1);

  const uploadProps = {
    accept: 'image/*',
    listType: "picture",
    maxCount: 1,
    beforeUpload: (file) => {
      const isImage = file.type.split('/')[0] === 'image';
      if (!isImage) {
        message.error('只允许上传图片文件');
        setFileList([]);
        return false;
      }
      const isNormalSize = file.size / 1024 / 1024 < 8;
      if (!isNormalSize) {
        message.error('文件过大');
        setFileList([]);
        return false;
      }
      setFileList([...fileList, file]);
      return false;
    },
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
  };

  const handleSubmit = (value) => {
    setUploading(true);
    setDisabledRadio(true);

    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('image', file);
    });
    formData.append("api", value.type);

    fetch('https://tools.b9.ink/api/searchPic.php', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        if (data.code === 0) {
          setResultData(data.data);
          setShowResult(true);
          setCurrent(1);
        } else {
          message.error(data.message);
        }
        setUploading(false);
        setDisabledRadio(false);

      })
      .catch((err) => {
        message.error(err.message);
        setUploading(false);
        setDisabledRadio(false);
      })
      .finally(() => {
        setUploading(false);
        setDisabledRadio(false);
      });
  };

  return (
    <>
      <Breadcrumb
        style={{ margin: '16px 4px' }}
        items={[
          {
            title: <Link to='/'>首页</Link>,
          },
          {
            title: '以图搜图',
          }
        ]}
      />      <Layout
        style={{
          padding: '24px 0',
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
        <Content
          style={{
            padding: '0 24px',
          }}
        >
          <Row gutter={[16, 8]}>
            <Col span={24} lg={12}>
              <Form layout="vertical" initialValues={{ type: "iqdb" }} onFinish={handleSubmit}>
                <Form.Item>
                  <Upload {...uploadProps}>
                    <Button icon={<UploadOutlined />}>选择图片</Button>
                  </Upload>
                  <Space direction="inline" style={{ marginTop: 10, float: "right" }} >
                    <Form.Item name="type" style={{ marginBottom: 0 }}>
                      <Radio.Group disabled={disabledRadio}>
                        <Radio value="iqdb">IQDB</Radio>
                        <Radio value="saucenao">SauceNAO</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Button type="primary" htmlType="submit" disabled={fileList.length === 0} loading={uploading}>上传并搜索</Button>
                  </Space>
                </Form.Item>

              </Form>
              <Card>
                <Typography>
                  <ul style={{ marginBlock: 0 }}>
                    <li>
                      仅支持 ACG 图片搜索
                    </li>
                    <li>
                      识别成功率取决于上传的图片，搜不到正确的图片可尝试裁剪图片或更换接口
                    </li>
                  </ul>
                </Typography>
              </Card>
            </Col>
            <Col span={24} lg={12}>
              {showResult ? (
                <Card>
                  <List
                    itemLayout="vertical"
                    size="small"
                    pagination={{
                      current: current,
                      onChange: (page) => {
                        setCurrent(page);
                      },
                      pageSize: 3,
                    }}
                    dataSource={resultData}
                    renderItem={(item) => (
                      <List.Item
                        key={item.title}
                        extra={
                          <img width={128} alt={item.title} src={item.thumbnail} referrerpolicy="no-referrer" />
                        }
                      >
                        <List.Item.Meta
                          title={item.title}
                          description={"相似度：" + item.similarity}
                        />
                        {item.description && item.description}
                        {
                          item.urls && item.urls.map((item) => {
                            return (<a href={item.url} target="_blank" rel="noopener noreferrer" style={{ display: 'block' }}>{item.site}</a>)
                          })
                        }
                      </List.Item>
                    )}
                    style={{
                      wordBreak: 'break-all'
                    }}
                  />
                </Card>
              ) : (
                <Card>
                  <h1 style={{ textAlign: 'center', color: colorTextTertiary }}>No Result</h1>
                </Card>
              )}

            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export default SearchPic;
