import { useState } from 'react';
import { Breadcrumb, Layout, Row, Col, Form, Input, Space, Button, message, theme } from 'antd';
import { Link } from 'react-router-dom';
import { encode, decode } from 'js-base64';

const { Content } = Layout;

const Random = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [inputText, setInputText] = useState('');
  const [resultData, setResultData] = useState('');

  const base64Encode = () => {
    setResultData(encode(inputText));
  };

  const base64Decode = () => {
    try {
      setResultData(decode(inputText));
    } catch (error) {
      //message.error(error.message);
      message.error('输入的文本无法解码');
    }
  };

  const exchangeInput = () => {
    let exchangeData = inputText;
    setInputText(resultData);
    setResultData(exchangeData);
  }

  const onChangeInput = (e) => setInputText(e.target.value);
  const onChangeResult = (e) => setResultData(e.target.value);

  return (
    <>
      <Breadcrumb
        style={{ margin: '16px 4px' }}
        items={[
          {
            title: <Link to='/'>首页</Link>,
          },
          {
            title: 'Base64编解码',
          }
        ]}
      />
      <Layout
        style={{
          padding: '24px 0',
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
        <Content
          style={{
            padding: '0 24px',
          }}
        >
          <Row gutter={[16, 8]}>
            <Col span={24} lg={12}>
              <Form layout="vertical" initialValues={{ min: 0, max: 100, count: 10 }}>
                <Form.Item label="输入内容">
                  <Input.TextArea rows={12} value={inputText} onChange={onChangeInput} />
                </Form.Item>
                <Space align="center" direction="inline" style={{ marginTop: 10, float: "right" }} >
                  <Button type="primary" onClick={base64Encode}>编码</Button>
                  <Button type="primary" onClick={base64Decode}>解码</Button>
                  <Button type="primary" onClick={exchangeInput}>交换</Button>
                </Space>
              </Form>
            </Col>
            <Col span={24} lg={12}>
              <div style={{ paddingTop: 2, paddingBottom: 8 }}>
                编码结果
              </div>
              <Input.TextArea rows={12} value={resultData} onChange={onChangeResult} />
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export default Random;
