import NotFound from './NotFound'
import Home from './Home'
import About from './About'
import BiliCover from './Tools/BiliCover'
import SearchPic from './Tools/SearchPic'
import Random from './Tools/Random'
import Eat from './Tools/Eat'
import BangumiOnAir from './Tools/BangumiOnAir'
import Nbnhhsh from './Tools/Nbnhhsh'
import Cidr from './Tools/Cidr'
import Hash from './Tools/Hash'
import Base64 from './Tools/Base64'
import ZipCode from './Tools/ZipCode'

const Router = [
  {
    path: '/',
    component: Home,
  },
  {
    title: '关于',
    path: '/about',
    component: About,
  },
  {
    title: '哔哩哔哩视频封面获取',
    path: '/bilicover',
    menu: true,
    component: BiliCover,
  },
  {
    title: '音乐解锁',
    path: '/unlock-music/',
    menu: true,
    blank: true,
  },
  {
    title: '随机数生成',
    path: '/random',
    menu: true,
    component: Random,
  },
  {
    title: '今天吃什么',
    path: '/eat',
    menu: true,
    component: Eat,
  },
  {
    title: '以图搜图',
    path: '/searchpic',
    menu: true,
    component: SearchPic,
  },
  {
    title: '番剧放送时间表',
    path: '/bangumionair',
    menu: true,
    component: BangumiOnAir,
  },
  {
    title: '能不能好好说话',
    path: '/nbnhhsh',
    menu: true,
    component: Nbnhhsh,
  },
  {
    title: 'IP CIDR地址计算',
    path: '/cidr',
    menu: true,
    component: Cidr,
  },
  {
    title: 'Hash计算',
    path: '/hash',
    menu: true,
    component: Hash,
  },
  {
    title: 'Base64编解码',
    path: '/base64',
    menu: true,
    component: Base64,
  },
  {
    title: '邮政编码',
    path: '/zipcode',
    component: ZipCode,
  },
  {
    title: '404 Not Found',
    path: '*',
    component: NotFound,
  },

]

export default Router;