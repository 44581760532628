import { useState } from 'react';
import { Breadcrumb, Layout, Typography, Row, Col, Form, Input, Card, List, message, theme } from 'antd';
import { Link } from 'react-router-dom';

const { Content } = Layout;
const { Text } = Typography;

const Nbnhhsh = () => {
  const {
    token: { colorBgContainer, borderRadiusLG, colorTextTertiary },
  } = theme.useToken();

  const [timer, setTimer] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [noResultText, setNoResultText] = useState('No Result');

  const [resultData, setResultData] = useState([]);

  const handleSubmit = (text) => {
    if (text.length > 1) {
      let formData = new FormData();
      formData.append("text", text);

      fetch('https://lab.magiconch.com/api/nbnhhsh/guess', {
        method: 'POST',
        contentType: false,
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          //console.log(data);
          if (data.length !== 0) {
            setResultData(data);
            setShowResult(true);
          } else {
            setNoResultText('没有找到对应缩写');
            setShowResult(false);
          }
        })
        .catch((err) => {
          message.error(err.message);
        });
    } else if (text.length === 1) {
      setNoResultText('没有找到对应缩写');
      setShowResult(false);
    } else {
      setNoResultText('No Result');
      setShowResult(false);
    }
  };

  const onChange = (event) => {
    const { value } = event.target;

    clearTimeout(timer);
    setTimer(setTimeout(() => handleSubmit(value), 500));
  }

  return (
    <>
      <Breadcrumb
        style={{ margin: '16px 4px' }}
        items={[
          {
            title: <Link to='/'>首页</Link>,
          },
          {
            title: '能不能好好说话',
          }
        ]}
      />
      <Layout
        style={{
          padding: '24px 0',
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
        <Content
          style={{
            padding: '0 24px',
          }}
        >
          <Row gutter={[16, 8]}>
            <Col span={24} lg={12}>
              <Form layout="vertical">
                <Form.Item label="含有首字母缩写的文本" name="text">
                  <Input placeholder="nbnhhsh" onChange={onChange} />
                </Form.Item>
              </Form>
              <Card>
                <Typography>
                  <ul style={{ marginBlock: 0 }}>
                    <li>
                      输入文本后自动转换
                    </li>
                    <li>
                      工具来源：<a href="https://github.com/itorr/nbnhhsh" target="_blank" rel="noopener noreferrer">https://github.com/itorr/nbnhhsh</a>
                    </li>
                  </ul>
                </Typography>
              </Card>
            </Col>
            <Col span={24} lg={12}>
              {showResult ? (
                <Card>
                  <List
                    itemLayout="vertical"
                    size="small"
                    dataSource={resultData}
                    renderItem={(item, index) => (
                      <List.Item
                        key={index}
                      >
                        <List.Item.Meta
                          title={item.name}
                          style={{ marginBlockEnd: "unset" }}
                        />
                        {
                          item.trans ? item.trans.map((item) => {
                            return (<span className="nbnhhshSpan">{item}</span>)
                          }) : <Text type="secondary">没有找到对应缩写</Text>
                        }
                      </List.Item>
                    )}
                    style={{
                      wordBreak: 'break-word'
                    }}
                  />
                </Card>
              ) : (
                <Card>
                  <h1 style={{ textAlign: 'center', color: colorTextTertiary }}>{noResultText}</h1>
                </Card>
              )}

            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export default Nbnhhsh;
