import { useState } from 'react';
import { Breadcrumb, Layout, Typography, Row, Col, Form, Input, Radio, Card, List, theme } from 'antd';
import { Link } from 'react-router-dom';
import { BigNumber } from 'bignumber.js';

const { Content } = Layout;

const Cidr = () => {
  const {
    token: { colorBgContainer, borderRadiusLG, colorTextTertiary },
  } = theme.useToken();

  const [ipMode, setIpMode] = useState(4);

  const [showResult, setShowResult] = useState(false);
  const [resultData, setResultData] = useState([]);

  const onChangeIpMode = (event) => {
    setIpMode(event.target.value);
    setShowResult(false);
  };

  const onChange = (event) => {
    const { value } = event.target;

    setShowResult(false);

    let addrNum = 0; // 可用地址
    let subnetMask = ''; // 掩码
    let netId = ''; // ID
    let startIpAddr = ''; // 起始
    let endIpAddr = ''; // 结束
    let broadcastAddr = ''; // 广播

    if (ipMode === 4) {
      value.replace(
        /(^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})\/(\d{1,2})$)/,
        (...args) => {
          // IP 0 - 255
          // 网络ID位数 0 - 32
          if (
            Number(args[2]) > 255 ||
            Number(args[2]) < 0 ||
            Number(args[3]) > 255 ||
            Number(args[3]) < 0 ||
            Number(args[4]) > 255 ||
            Number(args[4]) < 0 ||
            Number(args[5]) > 255 ||
            Number(args[5]) < 0 ||
            Number(args[6]) > 32 ||
            Number(args[6]) < 1
          ) {
            return args[1];
          }

          // 子网掩码二进制
          let subnetMaskBin = '1'
            .repeat(Number(args[6]))
            .padEnd(32, '0');

          // 获取子网掩码
          let subnetMaskArr = [];
          for (let i = 0; i < 4; i++) {
            subnetMaskArr.push(
              parseInt(subnetMaskBin.substr(i * 8, 8), 2)
            );
          }
          subnetMask = subnetMaskArr.join('.');

          // 获取网络ID 广播地址 可用地址
          let netIDArr = [];
          let broadcastAddrArr = [];
          subnetMaskArr.forEach((value, key) => {
            // 网络ID
            netIDArr.push(Number(args[key + 2]) & value);
            // 广播地址
            broadcastAddrArr.push(Number(args[key + 2]) | (255 ^ value));
            // 可用地址
            addrNum += (broadcastAddrArr[key] - netIDArr[key]) * Math.pow(256, 3 - key);
          });
          netId = netIDArr.join('.');
          broadcastAddr = broadcastAddrArr.join('.');
          addrNum = Math.abs(addrNum - 1);

          // 获取起始IP地址
          netIDArr[3] = netIDArr[3] + 1;
          startIpAddr = netIDArr.join('.');

          // 获取结束IP地址
          broadcastAddrArr[3] = broadcastAddrArr[3] - 1;
          endIpAddr = broadcastAddrArr.join('.');

          setResultData([
            ["可用地址", addrNum],
            ["子网掩码", subnetMask],
            ["网络ID", netId],
            ["起始IP地址", startIpAddr],
            ["结束IP地址", endIpAddr],
            ["广播地址", broadcastAddr]
          ]);
          setShowResult(true);
          return args[1];
        }
      );
    } else {
      value.replace(
        /(^([\da-fA-F:]+)\/(\d{1,3})$)/,
        (...args) => {
          // IPv6 地址长度 0 - 128
          if (
            parseInt(args[3]) > 128 ||
            parseInt(args[3]) < 1 ||
            !(/^([\da-fA-F]{1,4}:){6}((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)(\/([1-9]?\d|(1([0-1]\d|2[0-8]))))?$|^::([\da-fA-F]{1,4}:){0,4}((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)(\/([1-9]?\d|(1([0-1]\d|2[0-8]))))?$|^([\da-fA-F]{1,4}:):([\da-fA-F]{1,4}:){0,3}((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)(\/([1-9]?\d|(1([0-1]\d|2[0-8]))))?$|^([\da-fA-F]{1,4}:){2}:([\da-fA-F]{1,4}:){0,2}((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)(\/([1-9]?\d|(1([0-1]\d|2[0-8]))))?$|^([\da-fA-F]{1,4}:){3}:([\da-fA-F]{1,4}:){0,1}((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)(\/([1-9]?\d|(1([0-1]\d|2[0-8]))))?$|^([\da-fA-F]{1,4}:){4}:((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)(\/([1-9]?\d|(1([0-1]\d|2[0-8]))))?$|^([\da-fA-F]{1,4}:){7}[\da-fA-F]{1,4}(\/([1-9]?\d|(1([0-1]\d|2[0-8]))))?$|^:((:[\da-fA-F]{1,4}){1,6}|:)(\/([1-9]?\d|(1([0-1]\d|2[0-8]))))?$|^[\da-fA-F]{1,4}:((:[\da-fA-F]{1,4}){1,5}|:)(\/([1-9]?\d|(1([0-1]\d|2[0-8]))))?$|^([\da-fA-F]{1,4}:){2}((:[\da-fA-F]{1,4}){1,4}|:)(\/([1-9]?\d|(1([0-1]\d|2[0-8]))))?$|^([\da-fA-F]{1,4}:){3}((:[\da-fA-F]{1,4}){1,3}|:)(\/([1-9]?\d|(1([0-1]\d|2[0-8]))))?$|^([\da-fA-F]{1,4}:){4}((:[\da-fA-F]{1,4}){1,2}|:)(\/([1-9]?\d|(1([0-1]\d|2[0-8]))))?$|^([\da-fA-F]{1,4}:){5}:([\da-fA-F]{1,4})?(\/([1-9]?\d|(1([0-1]\d|2[0-8]))))?$|^([\da-fA-F]{1,4}:){6}:(\/([1-9]?\d|(1([0-1]\d|2[0-8]))))?$/.test(value))
          ) {
            return args[1];
          }

          // 子网掩码二进制
          let subnetMaskBin = '1'
            .repeat(Number(args[3]))
            .padEnd(128, '0');

          // 获取子网掩码
          let subnetMaskArr = [];
          for (let i = 0; i < 8; i++) {
            subnetMaskArr.push(
              parseInt(subnetMaskBin.substr(i * 16, 16), 2)
            );
          }
          subnetMask = subnetMaskArr.map((val) => val.toString(16)).join(':');

          // 扩展 IPv6
          let ipv6 = args[2];
          if (ipv6.includes('::')) {
            let parts = ipv6.split('::');
            let firstHalf = parts[0].split(':');
            let secondHalf = parts[1].split(':');
            let zerosToInsert = 8 - (firstHalf.length + secondHalf.length);
            let expandedParts = [];
            if (firstHalf.length > 0) {
              expandedParts = firstHalf;
            }
            for (let i = 0; i < zerosToInsert; i++) {
              expandedParts.push(0);
            }
            if (secondHalf.length > 0) {
              expandedParts = expandedParts.concat(secondHalf);
            }
            ipv6 = expandedParts.join(':');
          }

          // 获取网络ID 广播地址
          let netIDArr = [];
          let broadcastAddrArr = [];
          subnetMaskArr.forEach((value, key) => {
            let ipPart = Number.parseInt(ipv6.split(':')[key], 16);
            // 网络ID
            netIDArr.push(ipPart & value);
            // 广播地址
            broadcastAddrArr.push(ipPart | (65535 ^ value));
          });

          // 将结果转换为IPv6格式
          netId = netIDArr.map((val) => val.toString(16)).join(':');
          broadcastAddr = broadcastAddrArr.map((val) => val.toString(16)).join(':');

          // 获取起始IP地址
          startIpAddr = netId;

          // 获取结束IP地址
          endIpAddr = broadcastAddr;

          // 计算可用地址数量
          addrNum = BigNumber(2).pow(128 - parseInt(args[3])).toFixed();

          setResultData([
            ["可用地址", addrNum],
            ["子网掩码", subnetMask],
            ["网络ID", netId],
            ["起始IP地址", startIpAddr],
            ["结束IP地址", endIpAddr]
          ]);
          setShowResult(true);
          return args[1];
        }
      );
    }
  }

  return (
    <>
      <Breadcrumb
        style={{ margin: '16px 4px' }}
        items={[
          {
            title: <Link to='/'>首页</Link>,
          },
          {
            title: 'IP CIDR地址计算',
          }
        ]}
      />
      <Layout
        style={{
          padding: '24px 0',
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
        <Content
          style={{
            padding: '0 24px',
          }}
        >
          <Row gutter={[16, 8]}>
            <Col span={24} lg={12}>
              <Form layout="horizontal">
                <Form.Item label="IP 地址类型">
                  <Radio.Group onChange={onChangeIpMode} value={ipMode}>
                    <Radio value={4}>IPv4</Radio>
                    <Radio value={6}>IPv6</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="CIDR">
                  <Input onChange={onChange} />
                </Form.Item>
              </Form>
              <Card>
                <Typography>
                  <ul style={{ marginBlock: 0 }}>
                    <li>
                      输入 CIDR 地址（无类别域间路由），计算 CIDR 区块范围内的起始 IP 地址、结束 IP 地址、可用地址等
                    </li>
                    <li>
                      IPv4 CIDR 地址格式形如：<Typography.Text code>192.168.1.1/24</Typography.Text>
                    </li>
                    <li>
                      IPv6 CIDR 地址格式形如：<Typography.Text code>fe80:1234:abcd:001a::1/64</Typography.Text>
                    </li>
                  </ul>
                </Typography>
              </Card>
            </Col>
            <Col span={24} lg={12}>
              {showResult ? (
                <Card>
                  <List
                    dataSource={resultData}
                    renderItem={(item) => (
                      <List.Item>
                        {item[0]}：<Typography.Text code>{item[1]}</Typography.Text>
                      </List.Item>
                    )}
                  >
                  </List>
                </Card>
              ) : (
                <Card>
                  <h1 style={{ textAlign: 'center', color: colorTextTertiary }}>No Result</h1>
                </Card>
              )}

            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export default Cidr;
