import { theme } from 'antd';

const { useToken } = theme;

const NotFound = () => {
  const { token } = useToken();

  return (
    <div className="notfound-container">
      <div className="notfound" style={{ color: token.colorTextTertiary }}>
        404 Not Found
      </div>
    </div>
  );
};
export default NotFound;
